var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.skRow),function(sk,index){return _c('div',{key:index,staticClass:"scrollSkeleton",style:(_vm.rowStyle)},_vm._l((_vm.skList),function(sc,indexs){return _c('div',{key:indexs},[(!sc.inline)?_c('span',{staticClass:"skSpan",style:({
                    'height':sc.height,
                    'width':sc.width,
                    'margin-left':sc.ml,
                    'margin-top':sc.mt,
                    'margin-bottom':sc.mb,
                    'margin-right':sc.mr,
                    'float':sc.position||'left'})}):_vm._e(),(sc.inline)?_c('div',{staticClass:"skDiv"},[_c('span',{staticClass:"skSpan",style:({
                    'height':sc.height,
                    'width':sc.width,
                    'margin-left':sc.ml,
                    'margin-top':sc.mt,
                    'margin-bottom':sc.mb,
                    'margin-right':sc.mr,
                    'float':sc.position||'left'})})]):_vm._e()])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }