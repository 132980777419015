<template>
    <div>
        <div class="scrollSkeleton" :style="rowStyle" v-for="(sk,index) in skRow" :key="index">
            <div v-for="(sc,indexs) in skList" :key="indexs">
                <span v-if="!sc.inline"
                      class="skSpan"
                      :style="{
                        'height':sc.height,
                        'width':sc.width,
                        'margin-left':sc.ml,
                        'margin-top':sc.mt,
                        'margin-bottom':sc.mb,
                        'margin-right':sc.mr,
                        'float':sc.position||'left'}">
                </span>
                <div class="skDiv" v-if="sc.inline">
                     <span class="skSpan"
                           :style="{
                        'height':sc.height,
                        'width':sc.width,
                        'margin-left':sc.ml,
                        'margin-top':sc.mt,
                        'margin-bottom':sc.mb,
                        'margin-right':sc.mr,
                        'float':sc.position||'left'}">
                     </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Skeleton",
        props: [
            'skList',//骨架屏数据源
            'rowStyle',//自定义每行样式
        ],
        data() {
            return {
                skRow: []
            }
        },
        created() {
            //显示多少行 由数组第一个row字段决定
            let maxRow = this.skList[0].row || 1;
            for (let i = 0; i < maxRow; i++) {
                this.skRow.push({});
            }
        }
    }
</script>

<style scoped>
    .scrollSkeleton {
        width: 100%;
        margin-bottom: .2rem;
    }

    .scrollSkeleton .skSpan {
        height: .2rem;
        margin-bottom: .1rem;
        background:-webkit-gradient(linear,left top,right top,color-stop(25%, rgba(242, 242, 242, 0.6)),color-stop(37%,#e6e6e6),color-stop(63%,rgba(242, 242, 242, 0.6)));background:linear-gradient(90deg,rgba(242, 242, 242, 0.6) 25%,#e6e6e6 37%,rgba(242, 242, 242, 0.6) 63%);background-size:400% 100%;-webkit-animation:ant-skeleton-loading 1.4s ease infinite;animation:backgroundChange 1.4s ease infinite
    }

    .scrollSkeleton .skDiv {
        width: 100%;
        height: .2rem;
        float: left;
        margin-bottom: .1rem;
    }

    .scrollSkeleton:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
    }

    @keyframes backgroundChange {
        0%{
            background-position:100% 50%
        }
        100%{
            background-position:0 50%
        }
    }
</style>